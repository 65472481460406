.skeleton-mainImageContainer {
  align-self: center;
  border-radius: 25px;
  cursor: pointer;
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 1;
  height: 443px;
  justify-self: center;
  position: relative;
  width: 443px;
}

.skeleton-mainImageContainerReduced {
  height: 336px;
  width: 336px;
}

.skeleton-imageContainer {
  align-self: center;
  border-radius: 25px;
  cursor: pointer;
  height: 213px;
  justify-self: center;
  position: relative;
  width: 213px;
}

.skeleton-imageContainerReduced {
  height: 158px;
  width: 158px;
}

.skeleton-box {
  cursor: progress;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgb(196, 196, 196, 0.3);
  }
  100% {
    background-color: rgb(110, 110, 110, 0.3);
  }
}
