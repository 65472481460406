.featuredCollection-container {
  margin-top: 60px;
}

.featuredCollection-titleContainer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 18px;
}

.featuredCollection-title {
  color: #363637;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 29px;
}

.featuredCollection-titleWhite {
  color: #ffffff;
}

.featuredCollection-viewAll {
  color: #929292;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
}

.featuredCollection-viewAll:hover {
  color: #363636;
}

.featuredCollection-viewAllDark {
  color: #929292;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
}

.featuredCollection-viewAllDark:hover {
  color: #ffffff;
}

.featuredCollection-seeAll {
  align-items: center;
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  display: flex;
  height: 157px;
  justify-content: center;
  width: 157px;
}

.featuredCollection-seeAllLabel {
  color: #363636;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.featuredCollection-seeAllLabelDark {
  color: #ffffff;
}
