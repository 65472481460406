.chromeHeader-innerHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 36px;
}

.chromeHeader-extensionTitle {
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 24px;
  min-height: 44px;
  text-align: center;
}

.chromeHeader-extensionTitleWhite {
  color: #ffffff;
}

.chromeHeader-extensionButton {
  align-items: center;
  background: #2d81ff;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  min-height: 64px;
  justify-content: space-evenly;
  width: 311px;
}

.chromeHeader-extensionButton:hover {
  background: #0066ff;
}

.chromeHeader-extensionButtonDescription {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
