.appHeader-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 52px;
  margin-top: 20px;
  width: 100%;
}

.appHeader-logo {
  align-items: flex-start;
  color: #121212;
  display: flex;
  width: 170px;
}

.appHeader-iconsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 90px;
}

.appHeader-iconButton {
  align-items: center;
  border-radius: 20px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  display: flex;
  min-height: 28px;
  justify-content: center;
  margin-left: 16px;
  min-width: 28px;
}

.appHeader-iconButtonDark {
  border: 1px solid #434343;
}
