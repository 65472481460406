.squareImage-imageContainer {
  align-self: center;
  border-radius: 25px;
  cursor: pointer;
  height: 213px;
  justify-self: center;
  position: relative;
  width: 213px;
}

.squareImage-imageContainerReduced {
  height: 159px;
  width: 159px;
}

.squareImage-image {
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  height: 212px;
  width: 212px;
}

.squareImage-imageReduced {
  height: 157px;
  width: 157px;
}

.squareImage-imageDark {
  border: 1px solid #434343;
}

.squareImage-imageDescription {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
  border-radius: 0px 0px 25px 25px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
}

.squareImage-title {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.squareImage-titleReduced {
  margin-left: 15px;
}

.squareImage-description {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  margin: 0px 0px 17px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.squareImage-descriptionReduced {
  margin: 0px 0px 12px 15px;
}
