.footer-description {
  color: #363637;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  margin-top: 110px;
}

.footer-descriptionWhite {
  color: #ffffff;
}
