.heroSquareImage-mainImageContainer {
  align-self: center;
  border-radius: 25px;
  cursor: pointer;
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-row-start: 1;
  height: 443px;
  justify-self: center;
  position: relative;
  width: 443px;
}

.heroSquareImage-mainImageContainerReduced {
  height: 336px;
  width: 336px;
}

.heroSquareImage-mainImage {
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  height: 442px;
  width: 442px;
}

.heroSquareImage-mainImageReduced {
  height: 334px;
  width: 334px;
}

.heroSquareImage-mainImageDark {
  border: 1px solid #434343;
}

.heroSquareImage-imageDescription {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #000000 100%);
  border-radius: 0px 0px 25px 25px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
}

.heroSquareImage-title {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  margin-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}

.heroSquareImage-description {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 17px;
  margin: 0px 0px 17px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 160px;
}
