.app-mainContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden auto;
  width: 100%;
}

.app-darkMainContainer {
  background-color: #121212;
}

.app-innerContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: max-content;
  margin: 30px;
  width: 1440px;
}

.app-titleContainer {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin-bottom: 18px;
}

.app-title {
  color: #363637;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 29px;
}

.app-titleWhite {
  color: #ffffff;
}

.app-viewAll {
  color: #929292;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
}

.app-viewAll:hover {
  color: #363636;
}

.app-viewAllDark {
  color: #929292;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  text-align: right;
}

.app-viewAllDark:hover {
  color: #ffffff;
}

.app-imagesContainer {
  column-gap: 20px;
  display: grid;
  grid-template-columns: repeat(5, 212px);
  row-gap: 20px;
}

.app-imagesContainer1280 {
  display: grid;
  grid-template-columns: repeat(4, 212px);
  column-gap: 20px;
  row-gap: 20px;
}

.app-imagesContainer1024 {
  display: grid;
  grid-template-columns: repeat(2, 158px);
  column-gap: 20px;
  row-gap: 20px;
}

.app-seeAll {
  align-items: center;
  border-radius: 25px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
  display: flex;
  height: 157px;
  justify-content: center;
  width: 157px;
}

.app-seeAllLabel {
  color: #363636;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
}

.app-seeAllLabelDark {
  color: #ffffff;
}
